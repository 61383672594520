/* global MatgenGlobal, $ */

import { getTemplateThumbUrl } from '../../+auth-global/data/index.js';
import SectionLoader from '../../-components/objects/SectionLoader/index.js';
import { getMicrositeVars } from '../../website-data-page/website-data/data.js';

export const appendLoader = ({
  modalSelector,
  loaderPrefix,
  showMessage = false,
} = {}) => {
  $(modalSelector).append(
    $(`
    <div id="${loaderPrefix}-modal-loader-wrapper">
      <div id="${loaderPrefix}-modal-loader-target" style="height:100%;width:100%;position:absolute;top:0;left:0;z-index:2500;"></div>
    </div>
  `)
  );
  SectionLoader.displayLoader({
    transitionTargetSelector: `${modalSelector} .modal-dialog .modal-content`,
    loaderTargetSelector: `#${loaderPrefix}-modal-loader-target`,
    loaderId: `#${loaderPrefix}-modal-content-loader`,
    loaderAnimationMarkup: SectionLoader.blockLoaderAnimation(),
    showMessage,
  });
};

export const removeLoader = ({ modalSelector, loaderPrefix } = {}) => {
  $(`#${loaderPrefix}-modal-loader-wrapper`).remove();
  $(`#${modalSelector} .modal-dialog .modal-content`).css('filter', 'initial');
};

export const richTextLoader = () => {
  $('#editor-rich-text-modal').append(
    $(`
    <div id="modal-loader-wrapper">
      <div id="modal-loader-target" style="height:100%;width:100%;position:absolute;top:0;left:0;z-index:2500;"></div>
    </div>
  `)
  );
  SectionLoader.displayLoader({
    transitionTargetSelector:
      '#editor-rich-text-modal .modal-dialog .modal-content',
    loaderTargetSelector: '#modal-loader-target',
    loaderId: 'modal-content-loader',
    loaderAnimationMarkup: SectionLoader.blockLoaderAnimation(),
    showMessage: true,
  });
};

export const richTextLoaderStop = () => {
  $('#modal-loader-wrapper').remove();
  $('#editor-rich-text-modal .modal-dialog .modal-content').css(
    'filter',
    'initial'
  );
};

const editorBackButton = ({ material_id } = {}) => {
  const opener = MatgenGlobal.getQueryParam('opener');
  const study_id = MatgenGlobal.getQueryParam('study_id');
  let link_href;
  if (MatgenGlobal.pages && typeof MatgenGlobal.page !== 'undefined') {
    if (MatgenGlobal.page - 1 < 0) {
      if (opener && MatgenGlobal.Openers[opener]) {
        link_href = `/${
          MatgenGlobal.Openers[opener]
        }${MatgenGlobal.buildQueryString({
          study_id,
        })}`;
      } else {
        link_href = `/${
          MatgenGlobal.TemplatePickerPage
        }${MatgenGlobal.buildQueryString({
          tags: MatgenGlobal.answerTags,
        })}`;
      }
    } else {
      const opts = {
        tags: MatgenGlobal.answerTags,
        material_id,
        page_id: MatgenGlobal.pages[MatgenGlobal.page - 1].id,
      };
      /*if (MatgenGlobal.selectedThemeColor) {
        opts.theme_color = MatgenGlobal.selectedThemeColor;
      }*/
      link_href = `/${MatgenGlobal.EditorPage}${MatgenGlobal.buildQueryString(
        opts
      )}`;
    }

    return `
    <a id="study-material-back" class="button--blue button--hollow ${
      MatgenGlobal.pages &&
      MatgenGlobal.pages.length > 0 &&
      MatgenGlobal.page - 1 >= 0
        ? 'page-back'
        : ''
    }" href="${link_href}">${
      MatgenGlobal.pages &&
      MatgenGlobal.pages.length > 0 &&
      MatgenGlobal.page - 1 >= 0
        ? 'Prev Page'
        : 'Exit'
    }</a>
    `;
  }
  return `
  <a class="button--blue button--hollow" href="/${MatgenGlobal.MyMaterialsPage}">Back</a>
  `;
};

const editorPagination = () => {
  return `
    <fieldset id="editor-pagination">
      <div class="toggle">
        ${MatgenGlobal.pages
          .map((page) => {
            return `
            <input type="radio" name="page-num[${page.number}]" id="page-num-${
              page.number
            }" ${
              page.number === MatgenGlobal.page ? 'checked="checked"' : ''
            } />
            <label for="page-num-${page.number}" class="editor-page-number">
              <a href="/study-materials.html?study_id=${
                MatgenGlobal.currentStudy.id
              }&page=${page.number}&materialid=${
              MatgenGlobal.currentMaterial.id
            }&navtool=${MatgenGlobal.navtool}
              ">Page ${page.number + 1}</a>
            </label>
          `;
          })
          .join('')}
      </div>
    </fieldset>
  `;
};

const editorRightSidebar = ({
  study_name,
  study_id,
  material_name,
  material_id,
  page_file_exists,
  template,
  type,
  healthStatus,
  ethnicity,
}) => {
  const newDateOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  return `
  <div id="matgen-right-sidebar" class="">
    <div class="sticky-menu" id="theme-color-main">
      <ul class="right-sidebar-main">
        <li class="right-sidebar-header list-group-item active d-flex flex-row sidebar-li">
          <div class="right-sidebar-title">Edit Template</div>
        </li>
        <li>
          <div id="theme-color-wrapper">
            <span style="margin-right:.5em;">Set Theme Color:</span>
            <span id="theme-color-picker"></span>
          </div>
        </li>
        <!--<li><a href="#" id="select-layout">Select a different layout</a></li>-->
      </ul>
    </div>
      ${
        study_name
          ? `
        <div class="material-data">
          <div><b>Folder Name:</b></div>
          <div><span id="material-name-edit" data-id="${study_id}" contenteditable>${study_name}</span></div>
        </div>
        `
          : ''
      }
      ${
        material_name
          ? `
          <div class="material-data">
            <div><b>Material Name:</b></div>
            <div><span id="material-name-edit" data-id="${material_id}" contenteditable>${material_name}</span></div>
          </div>
          `
          : ''
      }
      ${
        healthStatus
          ? `
        <div class="material-data">
          <div><b>Health Status:</b></div>
          ${healthStatus}
        </div>       
        `
          : ''
      }
      ${
        ethnicity
          ? `
        <div class="material-data">
          <div><b>Race/Ethnicity:</b></div>
          ${ethnicity}
        </div>       
        `
          : ''
      }

      <div class="material-data">
      <div><b>Template Name:</b></div>
      <div><span id="material-template-name">${template.name}</span></div>
    </div>
      <div class="material-data">
        <div><b>Template added to OutreachPro:</b></div>
        <div><span id="material-template-date">${new Date(
          template.created
        ).toLocaleString('en-US', newDateOptions)}</span></div>
      </div>

    ${
      MatgenGlobal.opener === 'nothing' &&
      MatgenGlobal.pages &&
      MatgenGlobal.pages.length > 1
        ? editorPagination()
        : ''
    }
  <div class="editor-buttons">

  ${editorBackButton({
    material_id,
  })}

  ${
    MatgenGlobal.pages &&
    MatgenGlobal.pages.length > 0 &&
    MatgenGlobal.pages.length - 1 > MatgenGlobal.page
      ? `<a id="study-material-next-page" data-id="${material_id}" data-study-id="${study_id}" data-template-id="${
          template.id
        }" data-next-page-id="${
          MatgenGlobal.pages[MatgenGlobal.page + 1].id
        }" class="button--blue" href="#">Next Page</a>`
      : `<a id="study-material-save" data-study-id="${study_id}" data-id="${material_id}" data-name="${material_name}" data-template-id="${template.id}" data-type="${type}" class="button--blue" style="padding:.25em 1em;" href="#">Save Material</a>`
  }

  ${
    !MatgenGlobal.currentStudy && material_id && page_file_exists
      ? `<!--<a id="study-material-download" data-id="${material_id}" class="button--blue button--hollow" href="#">Download</a>-->`
      : ''
  }
  </div>
  <div style="margin-top: 5em;"><a id="plain-lang-review" href="/assets/OutreachPro Plain Language Tips Resources and Review.docx.pdf" class="link-primary" target="_blank">Want a plain language review?</a></div>

  </div>

  `;
};

export const editorContainer = ({
  study_name,
  study_id,
  material_name,
  material_id,
  page_file_exists = false,
  template,
  type,
  healthStatus,
  ethnicity,
}) => {
  return `
  <div id="editor-container">
  <h1 id="question-subtext" class="" style="padding-top:.5em;">Hover and click, or use the menu to the side, to select</h1>
  <div class="container-fluid bg--blue-light step__container" id="tabfocus" tabindex="0">
    <div class="" id="matgen-controller">
    <!--<div class="row justify-content-center">
      ${
        material_name
          ? `<h1 class="color--blue">Editing: ${material_name}</h1>`
          : ''
      }
    </div>-->
      <div class="d-flex justify-content-center" id="matgen-editor-wrapper">

        <div tabindex="0" id="matgen-sidebar" class="m4c-matgen"></div>
        <div id="matgen-pad">
          <div class="m4c-matgen" id="nia-matgen-controller"></div>
        </div>
        ${editorRightSidebar({
          study_name,
          study_id,
          material_name,
          material_id,
          page_file_exists,
          template,
          type,
          healthStatus,
          ethnicity,
        })}
      </div>
    </div>
  </div>
  </div>
  `;
};

export const filePreview = async (template) => {
  if (template.preview_type === 'IMAGE') {
    const url = MatgenGlobal.Data.getTemplateFileURL(
      template.id,
      template.tenant_id,
      template.file_ext
    );

    const purl = MatgenGlobal.Data.getTemplateFileURL(
      template.id,
      template.tenant_id,
      template.preview_image_ext
    );
    return `<div style="margin:auto;text-align: center;" class="preview-thumb" id="preview-thumb">
              <a href="${url}" target="_blank">Click to Preview Full Material</a>
            </div>
            <div style="margin:auto;text-align: center;" class="preview-thumb" id="preview-thumb"><img data-template-=id="${template.id}" id="page-thumb" src="${purl}" alt="Preview of material">
            </div>`;
  } else if (template.preview_type === 'LINK') {
    return `<div style="margin:auto;text-align: center;" class="preview-thumb" id="preview-thumb">
              <a href="${template.preview_link}" target="_blank">Click to preview material.</a>
            </div>`;
  } else if (template.preview_type === 'SELF') {
    const url = MatgenGlobal.Data.getTemplateFileURL(
      template.id,
      template.tenant_id,
      template.file_ext
    );
    return `
            <img
              class="material-preview template-thumbnail"
              id="${template.id}-template-thumbnail"
              src="${await getTemplateThumbUrl(template)}"
              alt="${template.name} Image" />
            <div style="margin:auto;text-align: center;" class="preview-thumb" id="preview-thumb">
              <a href="${url}" target="_blank">Click to preview material.</a>
            </div>`;
  } else {
    return '';
  }
};

export const handleMissingThumb = async (c) => {
  const newImage = new Image();
  newImage.id = `${c.id}-template-thumbnail`;

  newImage.onerror = async (e) => {
    if (
      $(e.target).attr('src') !== '/assets/img/file-circle-question-light.svg'
    ) {
      $(`#${$(e.target).attr('id')}`)
        .attr('src', '/assets/img/file-circle-question-light.svg')
        .addClass('default filter-blue');
    }
  };
  newImage.src = await getTemplateThumbUrl(c);
};

export const populateStudyData = async (studyid) => {
  const objects = MatgenGlobal.editor.cur().fabric.getObjects();
  MatgenGlobal.microsite_vars = getMicrositeVars();
  MatgenGlobal.editor.cur().fabric.studyid = studyid;
  MatgenGlobal.UI.loading('Syncing to study data...');

  for (let i = 0; i < objects.length; i++) {
    if (objects[i].materialDate) {
      const t = MatgenGlobal.currentMaterial.updated.split(/[- :.T]/);
      const d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
      objects[i].text = d.toLocaleDateString('en-us', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      });
    }
    /*const dataConn = objects[i].studyDataConnection;

    if (dataConn && !objects[i].studyDataOverride) {
      const connectedData = MatgenGlobal.currentStudy.answers.find(
        (a) => a.question_id === dataConn
      );
      console.error('UUID?', dataConn);
      if (
        connectedData &&
        MatgenGlobal.currentStudy.questions[connectedData.question_id]
      ) {
        let tmp, durl;

        const obj = tryJSON(connectedData.answer);
        const component =
          MatgenGlobal.currentStudy.questions[connectedData.question_id]
            .component;
        if (obj.answer) {
          if (
            component === 'img-select-single' ||
            component === 'upload-single'
          ) {
            objects[i].src = obj.answer;
          } else {
            objects[i].text = obj.answer;
          }
        } else {
          switch (component) {
            default:
              tmp = decode(obj[0].userAnswer);
              if (tmp) {
                tmp = $(`<div>${tmp}</div>`).text();
                objects[i].text = tmp;
                MatgenGlobal.editor.cur().fabric.renderAll();
              }
              break;
            case 'img-select-single':
              tmp = obj.find((o) => o.userAnswer === true);
              if (
                isJsonAnswer(
                  MatgenGlobal.currentStudy.questions[connectedData.question_id]
                    .component
                )
              ) {
                if (tmp.text) {
                  tmp = tryJSON(tmp.text);
                } else {
                  tmp = tryJSON(tmp);
                }
              }
              durl = `https://m4c-matgen-microsites-new.s3.amazonaws.com/nia/template-001/assets/img/${decode(
                tmp.dir
              )}/${decode(tmp.src)}`;
              if (durl) {
                const oldWidth = objects[i].getScaledWidth();
                const oldHeight = objects[i].getScaledHeight();

                const r = await fetch(durl);
                const b = await r.blob();

                const dataURL = await new Promise((resolve) => {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    const durl = e.target.result;
                    resolve(durl);
                  };
                  reader.readAsDataURL(b);
                });

                const newImg = new Image();
                newImg.src = dataURL;

                newImg.onload = function () {
                  objects[i].setSrc(dataURL, (img) => {
                    const widthFactor = oldWidth / newImg.width;
                    const heightFactor = oldHeight / newImg.height;
                    const minFactor = Math.min(widthFactor, heightFactor);
                    img.scale(minFactor);
                    MatgenGlobal.editor.cur().fabric.renderAll();
                    newImg.src = '';
                  });
                };
              }

              break;
            case 'multi-input':
              tmp = obj
                .map((o) => {
                  return o.userAnswer;
                })
                .join('\n');
              if (tmp) {
                tmp = $(`<div>${tmp}</div>`).text();
                objects[i].text = tmp;
                MatgenGlobal.editor.cur().fabric.renderAll();
              }
              break;
            case 'upload-single':
              durl = obj[0].userAnswer;
              if (durl) {
                const oldWidth = objects[i].getScaledWidth();
                const oldHeight = objects[i].getScaledHeight();

                const r = await fetch(durl);
                const b = await r.blob();

                const dataURL = await new Promise((resolve) => {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    const durl = e.target.result;
                    resolve(durl);
                  };
                  reader.readAsDataURL(b);
                });

                const newImg = new Image();

                newImg.src = dataURL;

                newImg.onload = function () {
                  objects[i].setSrc(dataURL, (img) => {
                    const widthFactor = oldWidth / newImg.width;
                    const heightFactor = oldHeight / newImg.height;
                    const minFactor = Math.min(widthFactor, heightFactor);
                    img.scale(minFactor);
                    MatgenGlobal.editor.cur().fabric.renderAll();
                    newImg.src = '';
                  });
                };
              }
              break;
            case 'list-multi-input':
              //tmp = decode(obj[0].userAnswer);
              tmp = [];
              for (let i = 0; i < obj.length; i++) {
                tmp.push(
                  `${obj[i].answers.map((a) => `${a.userAnswer}`).join('\n')}`
                );
              }
              if (tmp) {
                tmp = $(`<div>${tmp.join('\n')}</div>`).text();
                objects[i].text = tmp;
                MatgenGlobal.editor.cur().fabric.renderAll();
              }
              break;
            case 'list-faq':
              break;
          }
          MatgenGlobal.editor.cur().fabric.renderAll();
        }
      } else {
        console.error(
          'No answer found for connected question.',
          MatgenGlobal.currentStudy.questions,
          connectedData
        );
      }
    }*/
  }
};
